import React, { memo, ReactElement, useCallback, useState } from 'react'
import { navigate } from 'gatsby'
import searchOptions from '../../templates/OurProperties/OurProperties.json'
import closeIcon from '../../assets/svg/close.svg'
import './PropertySearch.scss'
import PropertySearchInput from '../PropertySearchInput/PropertySearchInput'
import PropertySearchSelect, {
  OptionTypes,
} from '../PropertySearchSelect/PropertySearchSelect'

type NullableOptionTypes = OptionTypes | null

type SearchTypes = {
  searchTerm?: string
  searchType?: NullableOptionTypes
  searchPrice?: NullableOptionTypes
  searchLocation?: NullableOptionTypes
  searchBedroom?: NullableOptionTypes
  searchUnitType?: NullableOptionTypes
}

type PropertySearchPropTypes = {
  display?: boolean
  onSearchClose?: (display: boolean) => void
  onSearch?: (values: SearchTypes) => void
  color?: 'black' | 'white'
  values?: SearchTypes
  visibleAdvancedFilter?: boolean
  isStick?: boolean
  variant?: 'sticky'
}

const PropertySearch = ({
  display,
  onSearchClose,
  onSearch,
  color = 'black',
  values,
  visibleAdvancedFilter = false,
  isStick = false,
  variant,
}: PropertySearchPropTypes): ReactElement => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchType, setSearchType] = useState<OptionTypes | null>(null)
  const [searchPrice, setSearchPrice] = useState<OptionTypes | null>(null)
  const [searchLocation, setSearchLocation] = useState<OptionTypes | null>(null)
  const [searchBedroom, setSearchBedroom] = useState<OptionTypes | null>(null)
  const [searchUnitType, setSearchUnitType] = useState<OptionTypes | null>(null)

  const handleSearch = useCallback(() => {
    if (onSearchClose) {
      onSearchClose(false)
    }
    if (onSearch) {
      onSearch({
        searchTerm,
        searchType,
        searchPrice,
        searchLocation,
        searchBedroom,
        searchUnitType,
      })
    }
    navigate('/properties/', {
      state: {
        searchTerm,
        searchType,
        searchPrice,
        searchLocation,
      },
    })
  }, [
    onSearchClose,
    onSearch,
    searchTerm,
    searchType,
    searchPrice,
    searchLocation,
    searchBedroom,
    searchUnitType,
  ])

  const handleSearchTypeSelect = useCallback((selected) => {
    setSearchType(selected)
    setSearchBedroom(null)
    setSearchUnitType(null)
  }, [])

  return (
    <div
      className={`header-property-search-container${display ? ' display' : ''}${
        variant === 'sticky' ? ' sticky' : ''
      }${isStick ? ' stick' : ''} variant-${color}`}
    >
      <div className="header-property-search">
        <button
          type="button"
          className="header-property-search-exit"
          onClick={(): void => onSearchClose && onSearchClose(false)}
        >
          <img
            src={closeIcon}
            alt="closeIcon"
            className="search-property-content-exit-icon-default"
          />
        </button>
        <h2 className="header-property-search-title">
          <small>Discover your </small> Dream Home
        </h2>
        <div className="header-property-search-fields">
          <PropertySearchInput
            placeholder="Search Location"
            setSearchTerm={setSearchTerm}
            defaultValue={values?.searchTerm}
          />
          <PropertySearchSelect
            options={searchOptions.type}
            placeholder="Property Type"
            onChange={handleSearchTypeSelect}
            defaultValue={values?.searchType}
          />
          <PropertySearchSelect
            options={searchOptions.price}
            placeholder="Price Range"
            onChange={(selected): void =>
              setSearchPrice(selected as OptionTypes)
            }
            defaultValue={values?.searchPrice}
          />
          <div
            className={`header-property-search-advanced-filters${
              !visibleAdvancedFilter || values?.searchType?.value === 'Office'
                ? ' hidden'
                : ''
            }`}
          >
            {['House and Lot', 'Condominium', ''].includes(
              searchType?.value || '',
            ) && (
              <h2 className="header-property-search-advanced-filters-title">
                Advanced Filters
              </h2>
            )}
            {['House and Lot', ''].includes(searchType?.value || '') && (
              <PropertySearchSelect
                options={searchOptions.bedroom}
                placeholder="No. of Bedrooms"
                onChange={(selected): void =>
                  setSearchBedroom(selected as OptionTypes)
                }
                defaultValue={values?.searchBedroom}
              />
            )}
            {['Condominium', ''].includes(searchType?.value || '') && (
              <PropertySearchSelect
                options={searchOptions.unit}
                placeholder="Unit Type"
                onChange={(selected): void =>
                  setSearchUnitType(selected as OptionTypes)
                }
                defaultValue={values?.searchUnitType}
              />
            )}
          </div>
          <button
            className="header-property-search-submit"
            type="button"
            onClick={handleSearch}
          >
            Find Properties
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(PropertySearch)
