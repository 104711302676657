import React, { ReactElement } from 'react'
import './NavSliderContent.scss'

import CaretTriangleIcon from '../../assets/svg/caret-triangle-red.svg'

type NavType = {
  name: string
  url?: string
}

type NavSliderContentType = {
  navItem: NavType
  subItems: NavType[]
  isOpened: boolean
  setOpened(isOpened: boolean): void
  className?: string
}

const NavSliderContent = ({
  navItem,
  setOpened,
  isOpened,
  subItems,
  className,
}: NavSliderContentType): ReactElement => {
  return (
    <div className={`nav-slider-content${className ? ` ${className}` : ''}`}>
      <div
        className={`nav-slider-content-container ${isOpened ? 'active' : ''}`}
        onClick={(): void => setOpened(!isOpened)}
        onKeyDown={(): void => setOpened(!isOpened)}
        role="button"
        aria-label="nav-slider-content-container"
        tabIndex={0}
      >
        <p className="nav-slider-content-container-dropdown">{navItem.name}</p>
        <img src={CaretTriangleIcon} alt="caret triangle" />
      </div>

      {isOpened && (
        <ul className="nav-slider-content-hide">
          {subItems.map((items) => (
            <li key={items.name}>
              <a
                href={items.url}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-slider-content-hide-link"
              >
                {items.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default NavSliderContent
