import React, { ReactElement, useState, memo, useCallback } from 'react'
import { graphql, Link, useStaticQuery, navigate } from 'gatsby'
import './Header.scss'
import RouteLink from '../RouteLink/RouteLink'

import avidaLogo from '../../assets/svg/avida-logo.svg'
import searchIcon from '../../assets/svg/search-icon.svg'
import burgerIcon from '../../assets/svg/burger-icon.svg'
import menuBurger from '../../assets/svg/menu-burger.svg'
import arrowIcon from '../../assets/svg/arrow.svg'

import NavSlider from '../NavSlider/NavSlider'
import HeaderMenu from '../HeaderMenu/HeaderMenu'
import SideModalDefault from '../SideModalDefault/SideModalDefault'
import PropertySearch from '../PropertySearch/PropertySearch'

export type SearchOptions = {
  isStick?: boolean
  setStick?: (isStick: boolean) => void
}

type NavVariantTypes = 'burger' | 'back'

type HeaderQueryTypes = {
  reserveNow?: {
    url: string
  }
  bookAnOfficeAppointment?: {
    url: string
  }
}

export type HeaderPropTypes = {
  navButtonVariant?: NavVariantTypes
  returnPageUrl?: string
}

const navItems = [
  {
    name: 'Properties',
    url: '/properties/',
  },
  {
    name: 'Neighborhoods',
    url: '/neighborhoods/',
  },
  {
    name: 'Buyer’s Guide',
    url: '/buyers-guide/',
  },
  {
    name: 'Contact Us',
    url: '/contact-us/',
  },
  { name: 'Menu' },
]

const NavButton = ({
  onClick,
  variant = 'burger',
}: {
  onClick(): void
  variant?: NavVariantTypes
}): ReactElement => {
  return (
    <button type="button" onClick={onClick}>
      <img
        src={variant === 'burger' ? burgerIcon : arrowIcon}
        className={
          variant === 'burger' ? 'header-burger-icon' : 'header-arrow-icon'
        }
        alt={variant === 'burger' ? 'burger icon' : 'arrow icon'}
      />
    </button>
  )
}

const HeaderNavButton = memo(NavButton)

const Header = ({
  isStick,
  setStick,
  navButtonVariant = 'burger',
  returnPageUrl,
}: HeaderPropTypes & SearchOptions): ReactElement => {
  const { reserveNow, bookAnOfficeAppointment }: HeaderQueryTypes =
    useStaticQuery(graphql`
      query HeaderQuery {
        reserveNow: link(
          category: { eq: "external" }
          name: { eq: "reserve_now" }
        ) {
          ...LinkFields
        }
        bookAnOfficeAppointment: link(
          category: { eq: "external" }
          name: { eq: "book_an_office_appointment" }
        ) {
          ...LinkFields
        }
      }
    `) || {}
  const [isSearchDisplayed, setIsSearchDisplayed] = useState(false)
  const [navVariant] = useState<NavVariantTypes>(navButtonVariant)
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const handleNavButtonClick = useCallback(() => {
    if (navVariant === 'burger') {
      setIsNavOpen(true)
    } else {
      if (returnPageUrl) {
        return navigate(returnPageUrl)
      }
      window.history.back()
    }
  }, [navVariant])

  const handleShowMenu = (): void => {
    setShowMenu(!showMenu)
  }

  return (
    <>
      <header className="header">
        <div className="header-content">
          <div className="burger-container">
            <HeaderNavButton
              onClick={handleNavButtonClick}
              variant={navVariant}
            />
          </div>
          <div className="logo-container">
            <RouteLink to="/">
              <img src={avidaLogo} alt="avidaLogo" />
            </RouteLink>
          </div>
          {showMenu && (
            <SideModalDefault onClose={handleShowMenu}>
              <HeaderMenu showMenu={showMenu} onShow={setShowMenu} />
            </SideModalDefault>
          )}
          <nav className="main-nav" data-testid="main-nav-container">
            {navItems.map((navItem) => {
              if (navItem.name.match(/Menu/gi)) {
                return (
                  <button
                    type="button"
                    className="nav-item burger"
                    key={navItem.name}
                    onClick={(): void => setShowMenu(!showMenu)}
                  >
                    <img src={menuBurger} alt="menuBurger" />
                    {navItem.name}
                  </button>
                )
              }
              return (
                <div className="nav-item" key={navItem.name}>
                  <Link
                    to={`${navItem.url}`}
                    activeClassName="active"
                    partiallyActive
                  >
                    {navItem.name}
                  </Link>
                </div>
              )
            })}
          </nav>
          <div className="search-section">
            <a
              className="search-section-appointment"
              href={bookAnOfficeAppointment?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Book an Office Appointment
            </a>

            <div
              className="search-container"
              onClick={(): void =>
                setStick
                  ? setStick(!isStick)
                  : setIsSearchDisplayed(!isSearchDisplayed)
              }
              onKeyDown={(): void =>
                setStick
                  ? setStick(!isStick)
                  : setIsSearchDisplayed(!isSearchDisplayed)
              }
              role="button"
              aria-label="search-container"
              tabIndex={0}
            >
              <div className="search-container-overlay">
                <img src={searchIcon} alt="searchIcon" />
              </div>
              <div
                className={`search-container-circle-${
                  !!isStick || !!isSearchDisplayed ? 'red' : 'grey'
                }`}
                tabIndex={0}
                role="button"
                aria-label="search-container"
              />
            </div>
          </div>
        </div>
      </header>
      <NavSlider isOpen={isNavOpen} onClose={(): void => setIsNavOpen(false)} />
      <PropertySearch
        display={isSearchDisplayed}
        onSearchClose={setIsSearchDisplayed}
      />
    </>
  )
}

export default memo(Header)
